export const formatPrice = (price: number): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(price);
};

export const parseProductText = (text: string) => {
  const lines = text.split('\n');
  const product = lines.find(line => line.startsWith('🏷️'))?.split(': ')[1] || '';
  const celebrity = lines.find(line => line.startsWith('👤'))?.split(': ')[1] || '';
  const price = lines.find(line => line.startsWith('💰'))?.split('$')[1] || '';
  const description = lines.find(line => line.startsWith('📝'))?.split(': ')[1] || '';
  const url = lines.find(line => line.startsWith('🔗'))?.split(': ')[1] || '#';

  return {
    product,
    celebrity,
    price,
    description,
    url
  };
};