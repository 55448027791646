import React from 'react';
import { Users, Star, MessageCircle, TrendingUp } from 'lucide-react';

export function CommunityPage() {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-6">
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center gap-3 mb-8">
          <Users className="w-8 h-8 text-[#F8CA7E]" />
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white">Stardom Community</h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Fan Collaborations */}
          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm">
            <div className="flex items-center gap-3 mb-4">
              <Star className="w-6 h-6 text-[#F8CA7E]" />
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Fan Collaborations</h2>
            </div>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              Connect with other fans to create unique projects and initiatives for your favorite celebrities.
            </p>
            <button className="w-full bg-[#F8CA7E] text-white py-2 rounded-lg hover:bg-[#e5b86b] transition-colors">
              Start Collaborating
            </button>
          </div>

          {/* Discussion Forums */}
          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm">
            <div className="flex items-center gap-3 mb-4">
              <MessageCircle className="w-6 h-6 text-[#F8CA7E]" />
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Discussion Forums</h2>
            </div>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              Join conversations about your favorite stars, their projects, and upcoming events.
            </p>
            <button className="w-full bg-[#F8CA7E] text-white py-2 rounded-lg hover:bg-[#e5b86b] transition-colors">
              Join Discussions
            </button>
          </div>

          {/* Trending Topics */}
          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm">
            <div className="flex items-center gap-3 mb-4">
              <TrendingUp className="w-6 h-6 text-[#F8CA7E]" />
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Trending Topics</h2>
            </div>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              Stay updated with the latest trending discussions and fan activities.
            </p>
            <button className="w-full bg-[#F8CA7E] text-white py-2 rounded-lg hover:bg-[#e5b86b] transition-colors">
              Explore Trends
            </button>
          </div>
        </div>

        {/* Coming Soon Banner */}
        <div className="mt-8 bg-gradient-to-r from-[#F8CA7E] to-[#e5b86b] rounded-xl p-8 text-white text-center">
          <h2 className="text-2xl font-bold mb-2">Community Features Coming Soon!</h2>
          <p className="text-lg opacity-90">
            We're working on exciting new ways for fans to connect and collaborate. Stay tuned!
          </p>
        </div>
      </div>
    </div>
  );
}