import React from 'react';
import { MapPin, Link as LinkIcon, Instagram, Twitter } from 'lucide-react';
import { Celebrity } from '../../types/celebrity';
import { VideoCover } from './VideoCover';

interface ProfileHeaderProps {
  celebrity: Celebrity;
}

export function ProfileHeader({ celebrity }: ProfileHeaderProps) {
  const showVideo = celebrity.username === 'taylorswift' && celebrity.coverVideo;

  return (
    <div className="relative">
      {/* Cover Video/Photo */}
      <div className="relative">
        {/* Mobile Cover */}
        <div className="block md:hidden h-[360px] w-full overflow-hidden">
          <VideoCover 
            videoUrl={showVideo ? celebrity.coverVideo : undefined}
            fallbackImageUrl={celebrity.coverImage} 
          />
        </div>
        
        {/* Desktop Cover */}
        <div className="hidden md:block h-[312px] max-w-[820px] mx-auto overflow-hidden">
          <VideoCover 
            videoUrl={showVideo ? celebrity.coverVideo : undefined}
            fallbackImageUrl={celebrity.coverImage}
          />
        </div>
      </div>

      {/* Profile Info */}
      <div className="container mx-auto px-4">
        <div className="max-w-[820px] mx-auto">
          <div className="flex flex-col md:flex-row items-start gap-8 -mt-16 md:mt-8 relative z-10">
            {/* Profile Picture */}
            <div className="w-32 h-32 md:w-40 md:h-40 rounded-full border-4 border-white overflow-hidden shadow-lg mx-auto md:mx-0">
              <img
                src={celebrity.imageUrl}
                alt={celebrity.name}
                className="w-full h-full object-cover"
              />
            </div>

            {/* Info */}
            <div className="flex-1 text-center md:text-left">
              <div className="flex flex-col md:flex-row items-center md:items-start gap-4 mb-4">
                <h1 className="text-3xl font-bold text-gray-900 dark:text-white">{celebrity.name}</h1>
                {celebrity.verified && (
                  <svg className="w-6 h-6" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path 
                      d="M19.998 3.094L24.995 8.09L31.901 6.901L33.09 13.807L38.086 18.804L33.09 23.801L31.901 30.707L24.995 29.518L19.998 34.514L15.001 29.518L8.095 30.707L6.906 23.801L1.91 18.804L6.906 13.807L8.095 6.901L15.001 8.09L19.998 3.094Z" 
                      fill="#F7B960"
                    />
                    <path 
                      d="M17.222 25.728L12.666 21.172L14.788 19.05L17.222 21.484L25.209 13.497L27.331 15.619L17.222 25.728Z" 
                      fill="white"
                    />
                  </svg>
                )}
              </div>
              
              <div className="flex justify-center md:justify-start items-center gap-6 mb-4">
                <div>
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {celebrity.followers.toLocaleString()}
                  </span>
                  <span className="text-gray-600 dark:text-gray-400 ml-1">followers</span>
                </div>
                <div>
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {celebrity.following.toLocaleString()}
                  </span>
                  <span className="text-gray-600 dark:text-gray-400 ml-1">following</span>
                </div>
              </div>

              <p className="text-gray-700 dark:text-gray-300 mb-4">{celebrity.bio}</p>

              <div className="flex flex-wrap justify-center md:justify-start items-center gap-4 text-sm">
                {celebrity.location && (
                  <div className="flex items-center gap-1 text-gray-600 dark:text-gray-400">
                    <MapPin className="w-4 h-4" />
                    <span>{celebrity.location}</span>
                  </div>
                )}
                {celebrity.socialLinks.website && (
                  <a
                    href={celebrity.socialLinks.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-1 text-[#F8CA7E] hover:underline"
                  >
                    <LinkIcon className="w-4 h-4" />
                    <span>Official Website</span>
                  </a>
                )}
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex gap-2 w-full md:w-auto justify-center md:justify-start mt-4 md:mt-0">
              <button className="px-6 py-2 bg-[#F8CA7E] text-white rounded-lg font-medium hover:bg-opacity-90 transition-colors">
                Follow
              </button>
              <div className="flex gap-2">
                {celebrity.socialLinks.instagram && (
                  <a
                    href={celebrity.socialLinks.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-2 bg-gray-100 dark:bg-gray-800 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
                  >
                    <Instagram className="w-5 h-5 text-gray-600 dark:text-gray-400" />
                  </a>
                )}
                {celebrity.socialLinks.twitter && (
                  <a
                    href={celebrity.socialLinks.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-2 bg-gray-100 dark:bg-gray-800 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
                  >
                    <Twitter className="w-5 h-5 text-gray-600 dark:text-gray-400" />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}