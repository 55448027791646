import React, { useState } from 'react';
import { Search, Bot, Loader2 } from 'lucide-react';
import { CELEBRITY_IMAGES } from '../../types/celebrity';
import { useCelebritySearch } from '../../hooks/useCelebritySearch';
import { CelebritySearchResult } from './CelebritySearchResult';
import { StardomAIAssistant } from '../ai/StardomAIAssistant';
import { useNavigate } from 'react-router-dom';

const suggestedCelebrities = [
  { name: 'Taylor Swift', image: CELEBRITY_IMAGES['Taylor Swift'] },
  { name: 'Rihanna', image: CELEBRITY_IMAGES['Rihanna'] },
  { name: 'Cristiano Ronaldo', image: CELEBRITY_IMAGES['Cristiano Ronaldo'] },
  { name: 'Beyoncé', image: CELEBRITY_IMAGES['Beyoncé'] }
];

export function SearchHero() {
  const [query, setQuery] = useState('');
  const [showAI, setShowAI] = useState(false);
  const navigate = useNavigate();
  const { isLoading, error, data, searchCelebrity } = useCelebritySearch();

  const handleSearch = async (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }
    if (!query.trim() || isLoading) return;
    await searchCelebrity(query);
  };

  const handleCelebrityClick = (celebrity: string) => {
    const celebritySlug = celebrity.toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '');
    navigate(`/dashboard/celebrity/${celebritySlug}`);
  };

  return (
    <div className="flex flex-col px-4 bg-gradient-to-b from-white to-gray-50 dark:from-gray-900 dark:to-gray-800">
      <div className="max-w-3xl mx-auto w-full pt-8 pb-16">
        <div className="text-center space-y-3 mb-8">
          <h1 className="text-4xl font-bold text-[#F8CA7E]">
            Discover Celebrity Products
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-400">
            Search for exclusive merchandise, tickets, and more from your favorite celebrities
          </p>
        </div>

        <form onSubmit={handleSearch} className="relative mb-8">
          <div className="relative flex items-center">
            <Search className="absolute left-4 h-5 w-5 text-gray-400" />
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search for any celebrity products: merchandise, concert tickets..."
              className="w-full pl-12 pr-20 py-4 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-xl shadow-lg focus:outline-none focus:ring-2 focus:ring-[#F8CA7E] text-lg dark:text-white"
            />
            <div className="absolute right-3 flex gap-2">
              <button 
                type="button"
                onClick={() => setShowAI(true)}
                className={`p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors ${
                  isLoading ? 'bg-[#F8CA7E] text-white' : 'text-gray-600 dark:text-gray-400'
                }`}
                title="Ask StardomAI about this celebrity"
              >
                <Bot className="h-5 w-5" />
              </button>
              <button 
                type="submit"
                disabled={isLoading}
                className="bg-[#F8CA7E] text-white p-2 rounded-lg hover:bg-[#e5b86b] transition-colors disabled:opacity-50"
              >
                {isLoading ? (
                  <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                  <Search className="h-5 w-5" />
                )}
              </button>
            </div>
          </div>

          {error && (
            <div className="absolute top-full left-0 right-0 mt-2 p-3 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 rounded-lg text-sm">
              {error}
            </div>
          )}
        </form>

        {!data && !error && !isLoading && (
          <div className="text-center">
            <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">
              Popular Celebrities
            </p>
            <div className="flex flex-wrap justify-center gap-4">
              {suggestedCelebrities.map((celebrity) => (
                <button
                  key={celebrity.name}
                  onClick={() => handleCelebrityClick(celebrity.name)}
                  className="group flex flex-col items-center"
                >
                  <div className="w-16 h-16 rounded-full overflow-hidden mb-2 border-2 border-transparent group-hover:border-[#F8CA7E] transition-colors">
                    <img
                      src={celebrity.image}
                      alt={celebrity.name}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <span className="text-sm text-gray-700 dark:text-gray-300 group-hover:text-[#F8CA7E] transition-colors">
                    {celebrity.name}
                  </span>
                </button>
              ))}
            </div>
          </div>
        )}

        {data && (
          <div className="mt-8">
            <CelebritySearchResult data={data} />
          </div>
        )}
      </div>

      {showAI && <StardomAIAssistant onClose={() => setShowAI(false)} />}
    </div>
  );
}