import React, { useState, useRef, useEffect } from 'react';
import { Heart, Share2, ImageOff } from 'lucide-react';
import { formatPrice } from '../../utils/formatters';

interface ProductCardProps {
  id: string;
  imageUrl: string;
  title: string;
  celebrity: string;
  price: number;
  purchaseUrl?: string;
  isLive?: boolean;
}

export function ProductCard({ id, imageUrl, title, celebrity, price, purchaseUrl, isLive }: ProductCardProps) {
  const [isHovered, setIsHovered] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [showFullscreen, setShowFullscreen] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const fullscreenVideoRef = useRef<HTMLVideoElement>(null);
  const isVideo = imageUrl.endsWith('.mp4') || imageUrl.endsWith('.mov');
  const isKimAuction = title === 'Live Auction' && celebrity === 'Kim Kardashian';

  useEffect(() => {
    if (isVideo && videoRef.current) {
      const playVideo = async () => {
        try {
          videoRef.current!.muted = true;
          await videoRef.current!.play();
        } catch (error) {
          console.error('Error playing video:', error);
        }
      };
      playVideo();
    }
  }, [isVideo]);

  useEffect(() => {
    if (showFullscreen && fullscreenVideoRef.current) {
      fullscreenVideoRef.current.muted = false;
      fullscreenVideoRef.current.play().catch(console.error);
    }
  }, [showFullscreen]);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (isVideo) {
      if (videoRef.current) {
        videoRef.current.pause();
      }
      setShowFullscreen(true);
      document.body.style.overflow = 'hidden';
    } else if (purchaseUrl) {
      window.open(purchaseUrl, '_blank', 'noopener,noreferrer');
    }
  };

  const closeFullscreen = () => {
    if (fullscreenVideoRef.current) {
      fullscreenVideoRef.current.pause();
    }
    if (videoRef.current) {
      videoRef.current.play().catch(console.error);
    }
    setShowFullscreen(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <>
      <div 
        className="bg-white dark:bg-gray-800 rounded-xl overflow-hidden shadow-sm hover:shadow-md transition-shadow cursor-pointer relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleClick}
      >
        <div className="relative aspect-square">
          {isVideo ? (
            <video
              ref={videoRef}
              className="w-full h-full object-cover"
              muted
              loop
              playsInline
              autoPlay
              preload="auto"
            >
              <source src={imageUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : imageError ? (
            <div className="w-full h-full bg-gray-100 dark:bg-gray-700 flex items-center justify-center">
              <ImageOff className="w-8 h-8 text-gray-400 dark:text-gray-500" />
            </div>
          ) : (
            <img
              src={imageUrl}
              alt={title}
              className="w-full h-full object-cover"
              onError={() => setImageError(true)}
            />
          )}
          
          <div className={`absolute inset-0 bg-black/40 flex items-center justify-center transition-opacity duration-200 ${
            isHovered ? 'opacity-100' : 'opacity-0'
          }`}>
            <button className="bg-white text-black px-6 py-2 rounded-lg font-medium hover:bg-gray-100 transition-colors">
              {isKimAuction ? 'View Fullscreen' : isVideo ? 'Watch Live' : 'View Details'}
            </button>
          </div>

          {/* Action buttons with higher z-index */}
          <div className="absolute top-3 right-3 flex gap-2 z-20">
            <button 
              className="p-2 bg-white/90 rounded-full hover:bg-white transition-colors"
              onClick={(e) => {
                e.stopPropagation();
                // Handle like functionality
              }}
            >
              <Heart className="w-4 h-4" />
            </button>
            <button 
              className="p-2 bg-white/90 rounded-full hover:bg-white transition-colors"
              onClick={(e) => {
                e.stopPropagation();
                // Handle share functionality
              }}
            >
              <Share2 className="w-4 h-4" />
            </button>
          </div>

          {/* Live indicator with higher z-index */}
          {isLive && (
            <div className="absolute top-3 left-3 flex items-center gap-2 bg-white/90 px-3 py-1 rounded-full z-20">
              <div className="w-2 h-2 rounded-full bg-red-500 animate-pulse"></div>
              <span className="text-sm font-medium">LIVE</span>
            </div>
          )}
        </div>

        <div className="p-4">
          <div className="flex items-start justify-between gap-4">
            <div>
              <h3 className="font-medium text-sm dark:text-white">{title}</h3>
              <p className="text-xs text-gray-600 dark:text-gray-400 mt-1">by {celebrity}</p>
            </div>
            <span className="font-medium text-sm dark:text-white">{formatPrice(price)}</span>
          </div>
        </div>
      </div>

      {showFullscreen && isVideo && (
        <div 
          className="fixed inset-0 bg-black/90 flex items-center justify-center z-50"
          onClick={closeFullscreen}
        >
          <button
            onClick={closeFullscreen}
            className="absolute top-4 right-4 text-white text-4xl hover:opacity-75 transition-opacity z-20"
          >
            ×
          </button>
          <video
            ref={fullscreenVideoRef}
            className="max-w-[90%] max-h-[90vh] rounded-lg"
            controls
            autoPlay
            loop
            onClick={(e) => e.stopPropagation()}
          >
            <source src={imageUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
    </>
  );
}