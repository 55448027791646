import React, { createContext, useContext, useState, useEffect } from 'react';
import { SupabaseService } from '../services/SupabaseService';
import { AuthError } from '@supabase/supabase-js';

interface User {
  id: string;
  email: string;
}

interface AuthContextType {
  user: User | null;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<{ error: AuthError | null }>;
  signUp: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [supabase] = useState(() => SupabaseService.getInstance());

  useEffect(() => {
    const initAuth = async () => {
      try {
        // Clear any existing sessions to prevent token reuse
        await supabase.auth.signOut();
        
        const { data: { session }, error: sessionError } = await supabase.getSession();
        
        if (sessionError) {
          throw sessionError;
        }

        if (session?.user) {
          setUser({
            id: session.user.id,
            email: session.user.email || ''
          });
        }
      } catch (err) {
        console.error('Auth initialization error:', err);
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === 'SIGNED_IN' && session?.user) {
        setUser({
          id: session.user.id,
          email: session.user.email || ''
        });
      } else if (event === 'SIGNED_OUT' || event === 'TOKEN_REFRESHED') {
        setUser(null);
      }
      setLoading(false);
    });

    initAuth();

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, [supabase]);

  async function signIn(email: string, password: string) {
    try {
      // Clear any existing sessions before signing in
      await supabase.signOut();
      
      const { error } = await supabase.signInWithPassword({ email, password });
      return { error };
    } catch (err) {
      console.error('Sign in error:', err);
      return { error: err as AuthError };
    }
  }

  async function signUp(email: string, password: string) {
    try {
      const { error } = await supabase.auth.signUp({ email, password });
      if (error) throw error;
    } catch (err) {
      console.error('Sign up error:', err);
      throw err;
    }
  }

  async function signOut() {
    try {
      const { error } = await supabase.signOut();
      if (error) throw error;
      setUser(null);
    } catch (err) {
      console.error('Sign out error:', err);
      throw err;
    }
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center p-4">
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md">
          <p className="font-medium">Authentication Error</p>
          <p className="text-sm mt-1">Please try signing in again.</p>
        </div>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ user, loading, signIn, signUp, signOut }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};