import React, { useState } from 'react';
import { Search, Loader2 } from 'lucide-react';
import { useCelebritySearch } from '../../hooks/useCelebritySearch';
import { CelebritySearchResult } from './CelebritySearchResult';

export function CelebritySearch() {
  const [query, setQuery] = useState('');
  const { isLoading, error, data, searchCelebrity } = useCelebritySearch();

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!query.trim() || isLoading) return;
    await searchCelebrity(query);
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="text-center mb-8">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-2">
          Celebrity Information Search
        </h1>
        <p className="text-gray-600 dark:text-gray-400">
          Get real-time updates about your favorite celebrities
        </p>
      </div>

      <form onSubmit={handleSearch} className="mb-8">
        <div className="relative">
          <Search className="absolute left-4 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search for any celebrity (e.g., 'Kevin Hart', 'Taylor Swift')"
            className="w-full pl-12 pr-24 py-4 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-xl shadow-lg focus:outline-none focus:ring-2 focus:ring-[#F8CA7E] text-gray-900 dark:text-white text-lg"
          />
          <button
            type="submit"
            disabled={isLoading || !query.trim()}
            className="absolute right-3 top-1/2 -translate-y-1/2 px-6 py-2 bg-[#F8CA7E] text-white rounded-lg hover:bg-[#e5b86b] transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isLoading ? (
              <div className="flex items-center gap-2">
                <Loader2 className="w-5 h-5 animate-spin" />
                <span>Searching...</span>
              </div>
            ) : (
              'Search'
            )}
          </button>
        </div>
      </form>

      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 p-4 rounded-lg mb-6">
          {error}
        </div>
      )}

      {data && <CelebritySearchResult data={data} />}

      {!data && !error && !isLoading && (
        <div className="text-center text-gray-500 dark:text-gray-400 mt-12">
          <Search className="w-12 h-12 mx-auto mb-4 opacity-50" />
          <p>Search for a celebrity to see their latest updates</p>
        </div>
      )}
    </div>
  );
}