import React from 'react';
import { Link } from 'react-router-dom';

interface AuthLayoutProps {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
}

export function AuthLayout({ children, title, subtitle }: AuthLayoutProps) {
  return (
    <div className="min-h-screen flex">
      {/* Left side - Image */}
      <div className="hidden lg:block lg:w-1/2">
        <img
          src="https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/celebrity%20cover%20photos/Login%209%20(3).jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9jZWxlYnJpdHkgY292ZXIgcGhvdG9zL0xvZ2luIDkgKDMpLmpwZyIsImlhdCI6MTczMzQyODQ5NCwiZXhwIjoxNzY0OTY0NDk0fQ.gX1RqZtzVH7eXekmFgts7fvwwEM68jDzUWGi7v6u3cY&t=2024-12-05T19%3A54%3A53.734Z"
          alt="Authentication background"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Right side - Auth form */}
      <div className="flex-1 flex items-center justify-center p-4 sm:p-8 bg-gray-50">
        <div className="w-full max-w-md">
          <div className="bg-white rounded-2xl shadow-xl p-8 border border-gray-100">
            <div className="flex justify-between items-center mb-8">
              <div>
                <h1 className="font-['Damion'] text-4xl text-gray-900 mb-2">{title}</h1>
                {subtitle && (
                  <p className="text-gray-600">{subtitle}</p>
                )}
              </div>
              {title === "Standom" && (
                <div className="text-right">
                  <p className="text-sm text-gray-600">
                    {location.pathname === '/login' ? 'No Account?' : 'Already have an account?'}
                  </p>
                  <Link 
                    to={location.pathname === '/login' ? '/signup' : '/login'}
                    className="text-[#F8CA7E] hover:text-[#e5b86b] font-medium text-sm"
                  >
                    {location.pathname === '/login' ? 'Sign up' : 'Sign in'}
                  </Link>
                </div>
              )}
            </div>
            
            <div className="space-y-6">
              {children}
            </div>
          </div>

          {/* Footer */}
          <div className="mt-6 text-center">
            <p className="text-sm text-gray-600">
              By continuing, you agree to Stardom's{' '}
              <a href="#" className="text-[#F8CA7E] hover:text-[#e5b86b]">Terms of Service</a>
              {' '}and{' '}
              <a href="#" className="text-[#F8CA7E] hover:text-[#e5b86b]">Privacy Policy</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}