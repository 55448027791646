import { useState } from 'react';
import { CelebrityData } from '../types/celebrity';
import { StardomAppService } from '../services/api/StardomAppService';

export function useCelebritySearch() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<CelebrityData | null>(null);

  const searchCelebrity = async (name: string) => {
    if (!name.trim()) {
      setError('Please enter a celebrity name');
      return;
    }

    setIsLoading(true);
    setError(null);
    setData(null);

    try {
      const stardomApp = StardomAppService.getInstance();
      const celebrityData = await stardomApp.getCelebrityData(name);
      setData(celebrityData);
    } catch (err) {
      console.error('Celebrity search error:', err);
      setError('Unable to fetch celebrity data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    data,
    searchCelebrity
  };
}