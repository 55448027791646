import OpenAI from 'openai';
import { ERROR_MESSAGES } from '../utils/constants';

export class VoiceSynthesisService {
  private static instance: VoiceSynthesisService;
  private openai: OpenAI;
  private audioContext: AudioContext | null = null;
  private audioSource: AudioBufferSourceNode | null = null;

  private constructor() {
    this.openai = new OpenAI({
      apiKey: import.meta.env.VITE_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true
    });
  }

  static getInstance(): VoiceSynthesisService {
    if (!VoiceSynthesisService.instance) {
      VoiceSynthesisService.instance = new VoiceSynthesisService();
    }
    return VoiceSynthesisService.instance;
  }

  async speak(text: string): Promise<void> {
    try {
      // Stop any ongoing speech
      this.stop();

      // Create audio context if it doesn't exist
      if (!this.audioContext) {
        this.audioContext = new AudioContext();
      }

      // Format the response for better speech flow
      const formattedText = this.formatTextForSpeech(text);

      // Get speech audio from OpenAI with female voice
      const response = await this.openai.audio.speech.create({
        model: "tts-1",
        voice: "alloy", // Using Alloy voice for a natural female sound
        input: formattedText,
        speed: 1.0,
      });

      // Convert the response to an ArrayBuffer
      const audioData = await response.arrayBuffer();

      // Decode the audio data
      const audioBuffer = await this.audioContext.decodeAudioData(audioData);

      // Create and configure audio source
      this.audioSource = this.audioContext.createBufferSource();
      this.audioSource.buffer = audioBuffer;
      this.audioSource.connect(this.audioContext.destination);

      // Start playing
      this.audioSource.start(0);

      // Return a promise that resolves when audio finishes playing
      return new Promise((resolve) => {
        this.audioSource!.onended = () => {
          this.audioSource = null;
          resolve();
        };
      });

    } catch (error) {
      console.error('Speech synthesis error:', error);
      throw new Error(ERROR_MESSAGES.SPEECH_ERROR);
    }
  }

  stop(): void {
    if (this.audioSource) {
      try {
        this.audioSource.stop();
        this.audioSource.disconnect();
        this.audioSource = null;
      } catch (error) {
        console.error('Error stopping audio:', error);
      }
    }
  }

  private formatTextForSpeech(text: string): string {
    // Add natural pauses and format event information
    return text
      .replace(/\n\n/g, '. ')
      .replace(/Event:/g, 'There is an event')
      .replace(/Date:/g, 'happening on')
      .replace(/Venue:/g, 'at')
      .replace(/City:/g, 'in')
      .replace(/\n/g, ', ')
      .replace(/\s+/g, ' ')
      .trim();
  }
}