import React, { forwardRef } from 'react';

interface TickerContentProps {
  children: React.ReactNode;
  textColor: string;
  onAnimationEnd: () => void;
}

export const TickerContent = forwardRef<HTMLDivElement, TickerContentProps>(
  ({ children, textColor, onAnimationEnd }, ref) => (
    <div
      ref={ref}
      className="inline-block whitespace-nowrap text-xs md:text-sm leading-[3rem] md:leading-8 px-4 ticker-content"
      style={{ color: textColor }}
      onAnimationEnd={onAnimationEnd}
    >
      {children}
    </div>
  )
);

TickerContent.displayName = 'TickerContent';