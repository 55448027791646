import React, { useState, useEffect, useRef } from 'react';
import { Bot, Mic, MicOff, Loader2, X } from 'lucide-react';
import { StardomAIService } from '../../services/StardomAIService';
import { VoiceSynthesisService } from '../../services/VoiceSynthesisService';
import { TicketmasterService } from '../../services/TicketmasterService';

interface StardomAIAssistantProps {
  onClose: () => void;
}

export function StardomAIAssistant({ onClose }: StardomAIAssistantProps) {
  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const recognitionRef = useRef<any>(null);
  const transcriptTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (transcriptTimeoutRef.current) {
        clearTimeout(transcriptTimeoutRef.current);
      }
      stopRecognition();
    };
  }, []);

  const stopRecognition = () => {
    if (recognitionRef.current) {
      try {
        recognitionRef.current.stop();
        recognitionRef.current = null;
      } catch (error) {
        console.error('Error stopping recognition:', error);
      }
    }
  };

  useEffect(() => {
    if (isListening) {
      try {
        recognitionRef.current = new (window as any).webkitSpeechRecognition();
        recognitionRef.current.continuous = true;
        recognitionRef.current.interimResults = true;

        recognitionRef.current.onresult = (event: any) => {
          const last = event.results.length - 1;
          const text = event.results[last][0].transcript;
          setTranscript(text);

          if (transcriptTimeoutRef.current) {
            clearTimeout(transcriptTimeoutRef.current);
          }

          transcriptTimeoutRef.current = setTimeout(() => {
            if (!isLoading) {
              stopRecognition();
              setIsListening(false);
              handleQuery(text);
            }
          }, 1500);
        };

        recognitionRef.current.onend = () => {
          setIsListening(false);
        };

        recognitionRef.current.onerror = (event: any) => {
          console.error('Speech recognition error:', event.error);
          setError('Failed to start voice recognition. Please try again.');
          setIsListening(false);
        };

        recognitionRef.current.start();
      } catch (error) {
        console.error('Speech recognition initialization error:', error);
        setError('Voice recognition is not supported in your browser.');
        setIsListening(false);
      }
    } else {
      stopRecognition();
    }
  }, [isListening]);

  const handleQuery = async (query: string) => {
    if (!query.trim() || isLoading) return;

    setIsLoading(true);
    setError(null);

    try {
      const aiService = StardomAIService.getInstance();
      const voiceService = VoiceSynthesisService.getInstance();
      const ticketService = TicketmasterService.getInstance();

      await new Promise(resolve => setTimeout(resolve, 500));

      let responseText = '';

      if (query.toLowerCase().includes('show') || 
          query.toLowerCase().includes('concert') || 
          query.toLowerCase().includes('ticket')) {
        responseText = await ticketService.getEvents(query);
      } else {
        responseText = await aiService.getCelebrityInfo(query);
      }

      setResponse(responseText);
      
      try {
        await voiceService.speak(responseText);
      } catch (error) {
        console.error('Speech synthesis error:', error);
        setError('Unable to play audio response. Please read the text instead.');
      }
    } catch (error) {
      console.error('Error processing query:', error);
      setError('Sorry, I encountered an error. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const toggleListening = () => {
    if (isLoading) return;
    setIsListening(!isListening);
    if (isListening) {
      stopRecognition();
    }
    setTranscript('');
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-end justify-end p-4 z-[9999]">
      <div className="w-96 bg-white dark:bg-gray-800 rounded-lg shadow-xl overflow-hidden">
        <div className="p-4 bg-[#F8CA7E] text-white flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Bot className="w-5 h-5" />
            <span className="font-medium">StardomAI Assistant</span>
          </div>
          <button onClick={onClose} className="hover:opacity-75">
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="p-4 h-96 overflow-y-auto">
          {error && (
            <div className="mb-4 p-3 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 rounded-lg text-sm">
              {error}
            </div>
          )}

          {response && (
            <div className="mb-4 p-3 bg-gray-100 dark:bg-gray-700 rounded-lg">
              <p className="text-sm text-gray-700 dark:text-gray-300 whitespace-pre-line">
                {response}
              </p>
            </div>
          )}

          {transcript && (
            <div className="mb-4 p-3 bg-[#F8CA7E]/10 rounded-lg">
              <p className="text-sm text-gray-600 dark:text-gray-400">
                {transcript}
              </p>
            </div>
          )}

          {isLoading && (
            <div className="flex items-center justify-center gap-2 text-gray-500 dark:text-gray-400">
              <Loader2 className="w-4 h-4 animate-spin" />
              <span>Processing...</span>
            </div>
          )}
        </div>

        <div className="p-4 border-t border-gray-200 dark:border-gray-700">
          <button
            onClick={toggleListening}
            disabled={isLoading}
            className={`w-full flex items-center justify-center gap-2 py-2 px-4 rounded-lg transition-colors ${
              isListening
                ? 'bg-red-500 text-white'
                : 'bg-[#F8CA7E] text-white hover:bg-[#e5b86b]'
            } ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isListening ? (
              <>
                <MicOff className="w-5 h-5" />
                Stop Listening
              </>
            ) : (
              <>
                <Mic className="w-5 h-5" />
                Start Listening
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}