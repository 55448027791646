import React from 'react';
import { CelebrityData } from '../../types/celebrity';
import { formatDate } from '../../utils/formatters/dateFormatters';

interface CelebritySearchResultProps {
  data: CelebrityData;
}

export function CelebritySearchResult({ data }: CelebritySearchResultProps) {
  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
      <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">{data.name}</h2>
      
      <div className="space-y-6">
        {/* Biography */}
        <div>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">Biography</h3>
          <p className="text-gray-600 dark:text-gray-400">{data.bio}</p>
        </div>

        {/* Recent Project */}
        <div>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">Recent Project</h3>
          <div className="bg-gray-50 dark:bg-gray-700 rounded-lg overflow-hidden">
            <img 
              src={data.recentProject.imageUrl} 
              alt={data.recentProject.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-4">
              <h4 className="font-medium text-gray-900 dark:text-white mb-2">{data.recentProject.title}</h4>
              <p className="text-gray-600 dark:text-gray-400">{data.recentProject.description}</p>
            </div>
          </div>
        </div>

        {/* Recent Instagram Post */}
        <div>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">Latest Instagram Post</h3>
          <div className="bg-gray-50 dark:bg-gray-700 rounded-lg overflow-hidden">
            <img 
              src={data.recentInstagramPost.imageUrl} 
              alt="Instagram post"
              className="w-full h-48 object-cover"
            />
            <div className="p-4">
              <p className="text-gray-600 dark:text-gray-400">{data.recentInstagramPost.caption}</p>
            </div>
          </div>
        </div>

        {/* Upcoming Tour */}
        <div>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">Upcoming Tour</h3>
          <div className="bg-gray-50 dark:bg-gray-700 rounded-lg overflow-hidden">
            <img 
              src={data.upcomingTour.imageUrl} 
              alt={data.upcomingTour.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-4">
              <h4 className="font-medium text-gray-900 dark:text-white mb-2">{data.upcomingTour.title}</h4>
              <p className="text-gray-600 dark:text-gray-400">{data.upcomingTour.description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}