import axios from 'axios';
import { CelebrityData } from '../../types/celebrity';

export class StardomAppService {
  private static instance: StardomAppService;
  private wikipediaUrl = 'https://en.wikipedia.org/w/api.php';
  private mockData = {
    'kevin hart': {
      recentProject: {
        title: 'Reality Check Tour',
        imageUrl: 'https://images.unsplash.com/photo-1507676184212-d03ab07a01bf',
        description: 'Kevin Hart returns with his most personal tour yet'
      },
      upcomingTour: {
        title: 'Reality Check World Tour 2024',
        imageUrl: 'https://images.unsplash.com/photo-1514525253161-7a46d19cd819',
        description: 'Global comedy tour featuring all new material'
      }
    },
    'taylor swift': {
      recentProject: {
        title: 'The Eras Tour',
        imageUrl: 'https://images.unsplash.com/photo-1514525253161-7a46d19cd819',
        description: 'Record-breaking concert tour celebrating all eras'
      },
      upcomingTour: {
        title: 'The Eras Tour International',
        imageUrl: 'https://images.unsplash.com/photo-1514525253161-7a46d19cd819',
        description: 'International leg of the record-breaking Eras Tour'
      }
    }
  };

  private constructor() {}

  static getInstance(): StardomAppService {
    if (!StardomAppService.instance) {
      StardomAppService.instance = new StardomAppService();
    }
    return StardomAppService.instance;
  }

  async fetchBiography(name: string): Promise<string | null> {
    try {
      const response = await axios.get(this.wikipediaUrl, {
        params: {
          action: 'query',
          format: 'json',
          prop: 'extracts',
          exintro: true,
          explaintext: true,
          titles: name,
          origin: '*'
        }
      });

      const pages = response.data.query.pages;
      const pageId = Object.keys(pages)[0];
      const extract = pages[pageId]?.extract;
      
      if (!extract || pageId === '-1') {
        return `${name} is a renowned celebrity in the entertainment industry.`;
      }
      
      return extract;
    } catch (error) {
      console.error('Error fetching biography:', error);
      return `${name} is a renowned celebrity in the entertainment industry.`;
    }
  }

  getMockInstagramPost(name: string): { imageUrl: string; caption: string } {
    return {
      imageUrl: 'https://images.unsplash.com/photo-1586899028174-e7098604235b',
      caption: `Latest update from ${name}! Stay tuned for more exciting news!`
    };
  }

  async fetchCustomData(celebrityName: string): Promise<{ recentProject: any; upcomingTour: any }> {
    const normalizedName = celebrityName.toLowerCase().trim();
    const mockCelebrity = this.mockData[normalizedName as keyof typeof this.mockData];
    
    if (!mockCelebrity) {
      return {
        recentProject: {
          title: 'Latest Project',
          imageUrl: 'https://images.unsplash.com/photo-1586899028174-e7098604235b',
          description: `${celebrityName}'s newest venture`
        },
        upcomingTour: {
          title: 'Upcoming Events',
          imageUrl: 'https://images.unsplash.com/photo-1514525253161-7a46d19cd819',
          description: 'Stay tuned for upcoming events and appearances'
        }
      };
    }

    return mockCelebrity;
  }

  async getCelebrityData(name: string): Promise<CelebrityData> {
    try {
      const [bio, customData] = await Promise.all([
        this.fetchBiography(name),
        this.fetchCustomData(name)
      ]);

      const instagramData = this.getMockInstagramPost(name);

      return {
        name,
        bio: bio || `${name} is a renowned celebrity in the entertainment industry.`,
        recentProject: customData.recentProject,
        recentInstagramPost: instagramData,
        upcomingTour: customData.upcomingTour
      };
    } catch (error) {
      console.error('Error getting celebrity data:', error);
      throw error;
    }
  }
}