import React from 'react';
import { Sparkles } from 'lucide-react';
import { ProductCard } from '../product/ProductCard';
import { recommendedProducts } from '../../data/products';

export function RecommendedSection() {
  return (
    <section className="py-8">
      <div className="flex items-center gap-2 mb-6">
        <Sparkles className="w-6 h-6 text-stardom-light" />
        <h2 className="text-2xl font-bold dark:text-white">Recommended for You</h2>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {recommendedProducts.map((product) => (
          <ProductCard key={product.id} {...product} />
        ))}
      </div>
    </section>
  );
}