import { useRef, useEffect } from 'react';

export function useTickerAnimation(speed: number) {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    const content = contentRef.current;
    
    if (!container || !content) return;

    const startAnimation = () => {
      const contentWidth = content.offsetWidth;
      const duration = (contentWidth / speed) * 1000; // Convert speed to duration

      content.style.animation = `ticker ${duration}ms linear`;
      content.style.transform = 'translateX(100%)';
    };

    startAnimation();
  }, [speed]);

  const resetAnimation = () => {
    const content = contentRef.current;
    if (!content) return;

    content.style.animation = 'none';
    content.offsetHeight; // Trigger reflow
    const contentWidth = content.offsetWidth;
    const duration = (contentWidth / speed) * 1000;
    content.style.animation = `ticker ${duration}ms linear`;
  };

  return { containerRef, contentRef, resetAnimation };
}