import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Sidebar } from '../components/layout/Sidebar';
import { Header } from '../components/layout/Header';
import { BottomNavigation } from '../components/layout/BottomNavigation';
import { SearchHero } from '../components/search/SearchHero';
import { TrendingSection } from '../components/home/TrendingSection';
import { RecommendedSection } from '../components/home/RecommendedSection';
import { CategoryBrowse } from './explore/CategoryBrowse';
import { NewsPage } from './news/NewsPage';
import { StarversePage } from './starverse/StarversePage';
import { CommunityPage } from './community/CommunityPage';
import { CelebrityProfile } from './CelebrityProfile';
import { AccountSettings } from './settings/AccountSettings';
import { StardomAIAssistant } from '../components/ai/StardomAIAssistant';
import { CelebritySearch } from '../components/search/CelebritySearch';

export function Dashboard() {
  return (
    <div className="flex min-h-screen bg-[#FAFAFA] dark:bg-gray-900 transition-colors">
      <Sidebar />
      <div className="flex-1">
        <Header />
        <main className="mt-0 pb-28 lg:pb-0">
          <Routes>
            <Route
              path="/"
              element={
                <div>
                  <SearchHero />
                  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <TrendingSection />
                    <RecommendedSection />
                  </div>
                </div>
              }
            />
            <Route path="/ai" element={<CelebritySearch />} />
            <Route path="/community" element={<CommunityPage />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/explore" element={<CategoryBrowse />} />
            <Route path="/starverse" element={<StarversePage />} />
            <Route path="/celebrity/:username" element={<CelebrityProfile />} />
            <Route path="/settings" element={<AccountSettings />} />
          </Routes>
        </main>
        <BottomNavigation />
      </div>
    </div>
  );
}