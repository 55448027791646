import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Play, Bookmark } from 'lucide-react';
import { CELEBRITY_IMAGES } from '../types/celebrity';
import { BackButton } from '../components/common/BackButton';
import { ProfileHeader } from '../components/profile/ProfileHeader';

const CELEBRITIES = {
  taylorswift: {
    name: "Taylor Swift",
    username: "taylorswift",
    imageUrl: CELEBRITY_IMAGES['Taylor Swift'],
    coverImage: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/celebrity%20cover%20photos/436315563_984292446395907_3233579659610284551_n.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9jZWxlYnJpdHkgY292ZXIgcGhvdG9zLzQzNjMxNTU2M185ODQyOTI0NDYzOTU5MDdfMzIzMzU3OTY1OTYxMDI4NDU1MV9uLmpwZyIsImlhdCI6MTczMzM1MTgxNywiZXhwIjoxNzY0ODg3ODE3fQ.YhoDOi_RcANuSDiUBws3v5V8tHbJTGuI6UkFnL4CUkc",
    coverVideo: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/taylor%20swifts%20product%20images/Screen%20Recording%20Taylor%20Swifts%20cover%20video.mp4?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy90YXlsb3Igc3dpZnRzIHByb2R1Y3QgaW1hZ2VzL1NjcmVlbiBSZWNvcmRpbmcgVGF5bG9yIFN3aWZ0cyBjb3ZlciB2aWRlby5tcDQiLCJpYXQiOjE3MzM3ODg5MTMsImV4cCI6MTc2NTMyNDkxM30.hxoTH6ELqJzrqbPB8-HpBUPFgbyywCuHaTjRfQKag34",
    bio: "Singer-songwriter and entrepreneur. Shop exclusive merchandise and tour tickets.",
    location: "New York, NY",
    followers: 279000000,
    following: 0,
    verified: true,
    socialLinks: {
      website: "https://store.taylorswift.com",
      instagram: "https://instagram.com/taylorswift",
      twitter: "https://twitter.com/taylorswift13"
    },
    storeScreenshots: [
      {
        id: "store1",
        imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/taylor%20swifts%20product%20images/Taylor%20swift%20page%201.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy90YXlsb3Igc3dpZnRzIHByb2R1Y3QgaW1hZ2VzL1RheWxvciBzd2lmdCBwYWdlIDEucG5nIiwiaWF0IjoxNzMzNDQ0NzgxLCJleHAiOjE3NjQ5ODA3ODF9.9h7uSRKu9PjJd7DowR8eg6sAjbbPi6OZSG2-g3Tx_I0",
        url: "https://store.taylorswift.com"
      },
      {
        id: "store2",
        imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/taylor%20swifts%20product%20images/Taylor%20swift%20page%202.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy90YXlsb3Igc3dpZnRzIHByb2R1Y3QgaW1hZ2VzL1RheWxvciBzd2lmdCBwYWdlIDIucG5nIiwiaWF0IjoxNzMzNDQ0NzkxLCJleHAiOjE3NjQ5ODA3OTF9.jiRL9whoHxfy65sqLDYIyDVzrjYDw00T5oZ_ZzC-w7k",
        url: "https://store.taylorswift.com"
      }
    ]
  },
  rihanna: {
    name: "Rihanna",
    username: "rihanna",
    imageUrl: CELEBRITY_IMAGES['Rihanna'],
    coverImage: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/Rihanna%20product%20images/21820397_484368995277897_2228104942969683968_n-1024x682.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9SaWhhbm5hIHByb2R1Y3QgaW1hZ2VzLzIxODIwMzk3XzQ4NDM2ODk5NTI3Nzg5N18yMjI4MTA0OTQyOTY5NjgzOTY4X24tMTAyNHg2ODIuanBnIiwiaWF0IjoxNzMzNDQ3OTk0LCJleHAiOjE3NjQ5ODM5OTR9.1R-rdzbnQmgaDVipGVhlvYrWNjX3OnLI-ulEZX2_1EY",
    bio: "Entrepreneur, singer, and fashion icon. Shop exclusive Fenty Beauty products and merchandise.",
    location: "Barbados / Los Angeles",
    followers: 152000000,
    following: 1471,
    verified: true,
    socialLinks: {
      website: "https://rihanna.store/collections/apparel",
      instagram: "https://instagram.com/badgalriri",
      twitter: "https://twitter.com/rihanna"
    },
    storeScreenshots: [
      {
        id: "store1",
        imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/Rihanna%20product%20images/Screenshot%202024-12-05%20193253.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9SaWhhbm5hIHByb2R1Y3QgaW1hZ2VzL1NjcmVlbnNob3QgMjAyNC0xMi0wNSAxOTMyNTMucG5nIiwiaWF0IjoxNzMzNDQ1NDgyLCJleHAiOjE3NjQ5ODE0ODJ9.0yZuNeEvTW-77JfyOXyDnDFXD6R7kMD0ExmI4Av-Saw",
        url: "https://rihanna.store/collections/apparel"
      }
    ]
  },
  beyonce: {
    name: "Beyoncé",
    username: "beyonce",
    imageUrl: CELEBRITY_IMAGES['Beyoncé'],
    coverImage: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/Beyonce/431750462_984172903069009_1066547085413343218_n.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9CZXlvbmNlLzQzMTc1MDQ2Ml85ODQxNzI5MDMwNjkwMDlfMTA2NjU0NzA4NTQxMzM0MzIxOF9uLnBuZyIsImlhdCI6MTczMzQ0ODU1OSwiZXhwIjoxNzY0OTg0NTU5fQ.x5TCVxzp9jiuVqXKZARCzVXX2keAU9I3FUTvXOJS4hQ",
    bio: "Singer, songwriter, and entrepreneur. Shop exclusive merchandise and tour tickets.",
    location: "Los Angeles, CA",
    followers: 319000000,
    following: 0,
    verified: true,
    socialLinks: {
      website: "https://shop.beyonce.com",
      instagram: "https://instagram.com/beyonce",
      twitter: "https://twitter.com/beyonce"
    },
    storeScreenshots: [
      {
        id: "store1",
        imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/Beyonce/Screenshot%202024-12-05%20201451.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9CZXlvbmNlL1NjcmVlbnNob3QgMjAyNC0xMi0wNSAyMDE0NTEucG5nIiwiaWF0IjoxNzMzNDQ4NTE4LCJleHAiOjE3NjQ5ODQ1MTh9.n17kwdICftWtzinlEpBhSfSFxmgNfbNMhzkNZ4MsX_I",
        url: "https://shop.beyonce.com"
      },
      {
        id: "store2",
        imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/Beyonce/Screenshot%202024-12-05%20201526.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9CZXlvbmNlL1NjcmVlbnNob3QgMjAyNC0xMi0wNSAyMDE1MjYucG5nIiwiaWF0IjoxNzMzNDQ4NTI5LCJleHAiOjE3NjQ5ODQ1Mjl9.XYd5vOq8w7fkvfbAVEixWfGZEDNpbt47TgBFGiwVzxs",
        url: "https://shop.beyonce.com"
      }
    ]
  },
  cristianoronaldo: {
    name: "Cristiano Ronaldo",
    username: "cristianoronaldo",
    imageUrl: CELEBRITY_IMAGES['Cristiano Ronaldo'],
    coverImage: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/christiano%20Ronaldo/Screenshot%202024-12-05%20205206.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9jaHJpc3RpYW5vIFJvbmFsZG8vU2NyZWVuc2hvdCAyMDI0LTEyLTA1IDIwNTIwNi5wbmciLCJpYXQiOjE3MzM0NTA0MDQsImV4cCI6MTc2NDk4NjQwNH0.T2UBiTWb4Yo7PxFVlz5bd7Zayq-2zKVJA5x7gQx0MFE",
    bio: "Professional footballer and entrepreneur. Shop exclusive CR7 merchandise.",
    location: "Riyadh, Saudi Arabia",
    followers: 615000000,
    following: 570,
    verified: true,
    socialLinks: {
      website: "https://www.cristianoronaldo.com/shop",
      instagram: "https://instagram.com/cristiano",
      twitter: "https://twitter.com/cristiano"
    },
    storeScreenshots: [
      {
        id: "store1",
        imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/christiano%20Ronaldo/Screenshot%202024-12-05%20205535.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9jaHJpc3RpYW5vIFJvbmFsZG8vU2NyZWVuc2hvdCAyMDI0LTEyLTA1IDIwNTUzNS5wbmciLCJpYXQiOjE3MzM0NTAzNTQsImV4cCI6MTc2NDk4NjM1NH0.4yPk6KyIDW47_Cfvb9KtFi9AnnzPnvBHA-xNteqxkCk",
        url: "https://www.cristianoronaldo.com/shop"
      },
      {
        id: "store2",
        imageUrl: "https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/christiano%20Ronaldo/Screenshot%202024-12-05%20205722.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9jaHJpc3RpYW5vIFJvbmFsZG8vU2NyZWVuc2hvdCAyMDI0LTEyLTA1IDIwNTcyMi5wbmciLCJpYXQiOjE3MzM0NTAzNzEsImV4cCI6MTc2NDk4NjM3MX0.xMJBiwhA8grRx8LDINvhIajfruXQQD3OkvPoK-errJU",
        url: "https://www.cristianoronaldo.com/shop"
      }
    ]
  }
};

export function CelebrityProfile() {
  const { username } = useParams();
  const normalizedUsername = username?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '');
  const celebrity = normalizedUsername ? CELEBRITIES[normalizedUsername as keyof typeof CELEBRITIES] : null;

  if (!celebrity) {
    return (
      <div className="min-h-screen bg-white dark:bg-gray-900 flex items-center justify-center">
        <BackButton />
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
            Celebrity not found
          </h2>
          <p className="text-gray-600 dark:text-gray-400">
            The celebrity you're looking for doesn't exist in our database.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <BackButton />
      <div className="max-w-7xl mx-auto">
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden my-8 mx-4">
          <ProfileHeader celebrity={celebrity} />
          
          <div className="p-8">
            <div className="flex justify-center border-b border-gray-200 dark:border-gray-700 mb-8">
              <button className="px-8 py-4 border-b-2 border-[#F8CA7E] text-[#F8CA7E] flex items-center gap-2">
                <Grid className="w-5 h-5" />
                <span>Products</span>
              </button>
              <button className="px-8 py-4 text-gray-500 dark:text-gray-400 flex items-center gap-2">
                <Play className="w-5 h-5" />
                <span>Videos</span>
              </button>
              <button className="px-8 py-4 text-gray-500 dark:text-gray-400 flex items-center gap-2">
                <Bookmark className="w-5 h-5" />
                <span>Saved</span>
              </button>
            </div>

            <div className="grid grid-cols-1 gap-8">
              {celebrity.storeScreenshots.map((screenshot) => (
                <div key={screenshot.id} className="relative group">
                  <img
                    src={screenshot.imageUrl}
                    alt={`${celebrity.name}'s Store`}
                    className="w-full rounded-lg shadow-lg"
                  />
                  <a
                    href={screenshot.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity"
                  >
                    <button className="px-6 py-3 bg-[#F8CA7E] text-white rounded-lg hover:bg-[#e5b86b] transition-colors">
                      View on Official Website
                    </button>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}