import axios from 'axios';

export class TicketmasterService {
  private static instance: TicketmasterService;
  private baseUrl = 'https://app.ticketmaster.com/discovery/v2';
  private apiKey = 'YOUR_TICKETMASTER_API_KEY';

  private constructor() {}

  static getInstance(): TicketmasterService {
    if (!TicketmasterService.instance) {
      TicketmasterService.instance = new TicketmasterService();
    }
    return TicketmasterService.instance;
  }

  async getEvents(keyword: string): Promise<string> {
    try {
      const celebrity = this.extractCelebrityName(keyword);
      const events = this.getMockEvents(celebrity);
      
      if (!events.length) {
        return `I couldn't find any upcoming shows for ${celebrity}. Would you like me to search for other celebrities?`;
      }

      return `I found ${events.length} upcoming shows for ${celebrity}:\n\n${
        events.map(event => this.formatEvent(event)).join('\n\n')
      }`;
    } catch (error) {
      console.error('Ticketmaster API Error:', error);
      return 'I apologize, but I could not fetch the event information at the moment. Please try again later.';
    }
  }

  private extractCelebrityName(query: string): string {
    const keywords = query.toLowerCase();
    if (keywords.includes('kevin hart')) return 'Kevin Hart';
    if (keywords.includes('taylor swift')) return 'Taylor Swift';
    return query.split(' ').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');
  }

  private formatEvent(event: any): string {
    return `Event: ${event.name}
Date: ${event.date}
Venue: ${event.venue}
City: ${event.city}`;
  }

  private getMockEvents(celebrity: string): any[] {
    const mockEvents = {
      'Kevin Hart': [
        {
          name: 'Reality Check World Tour',
          date: 'April 15, 2024',
          venue: 'Madison Square Garden',
          city: 'New York'
        },
        {
          name: 'Reality Check World Tour',
          date: 'April 22, 2024',
          venue: 'Staples Center',
          city: 'Los Angeles'
        }
      ],
      'Taylor Swift': [
        {
          name: 'Eras Tour',
          date: 'May 10, 2024',
          venue: 'Wembley Stadium',
          city: 'London'
        },
        {
          name: 'Eras Tour',
          date: 'May 17, 2024',
          venue: 'MetLife Stadium',
          city: 'New Jersey'
        }
      ]
    };

    return mockEvents[celebrity] || [];
  }
}