import React, { useEffect, useRef } from 'react';

interface VideoCoverProps {
  videoUrl?: string;
  fallbackImageUrl: string;
}

export function VideoCover({ videoUrl, fallbackImageUrl }: VideoCoverProps) {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement && videoUrl) {
      const playVideo = async () => {
        try {
          await videoElement.play();
        } catch (error) {
          console.error('Error playing video:', error);
        }
      };

      playVideo();
    }
  }, [videoUrl]);

  if (!videoUrl) {
    return (
      <div className="relative w-full h-full bg-black">
        <img
          src={fallbackImageUrl}
          alt="Cover"
          className="w-full h-full object-cover"
        />
      </div>
    );
  }

  return (
    <div className="relative w-full h-full bg-black">
      <video
        ref={videoRef}
        className="w-full h-full object-cover"
        autoPlay
        loop
        muted
        playsInline
        poster={fallbackImageUrl}
      >
        <source 
          src={videoUrl} 
          type="video/mp4"
        />
        <img
          src={fallbackImageUrl}
          alt="Cover"
          className="w-full h-full object-cover"
        />
      </video>
    </div>
  );
}