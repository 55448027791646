import React from 'react';
import { useTickerAnimation } from './useTickerAnimation';
import { TickerContainer } from './TickerContainer';
import { TickerContent } from './TickerContent';

export interface NewsTickerProps {
  message: string;
  speed?: number;
  backgroundColor?: string;
  textColor?: string;
}

export function NewsTicker({
  message,
  speed = 50,
  backgroundColor = 'rgb(0 0 0 / 0.8)',
  textColor = 'white'
}: NewsTickerProps) {
  const { containerRef, contentRef, resetAnimation } = useTickerAnimation(speed);

  return (
    <TickerContainer ref={containerRef} backgroundColor={backgroundColor}>
      <div className="ticker-wrapper">
        <TickerContent
          ref={contentRef}
          textColor={textColor}
          onAnimationEnd={resetAnimation}
        >
          {message}
        </TickerContent>
      </div>
    </TickerContainer>
  );
}