import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export function BackButton() {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate('/dashboard')}
      className="fixed top-4 left-4 z-50 flex items-center gap-2 px-4 py-2 bg-white dark:bg-gray-800 rounded-lg shadow-md hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
    >
      <ArrowLeft className="w-5 h-5 text-gray-600 dark:text-gray-300" />
      <span className="text-gray-700 dark:text-gray-200 font-medium">Back</span>
    </button>
  );
}