export const featuredProducts = [
  {
    id: '1',
    imageUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/public/featured/cr7-cleats.jpg',
    title: 'CR7 Limited Edition Cleats',
    celebrity: 'Cristiano Ronaldo',
    price: 299.99
  },
  {
    id: '2',
    imageUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/public/featured/rare-beauty.jpg',
    title: 'Rare Beauty Collection',
    celebrity: 'Selena Gomez',
    price: 89.99
  },
  {
    id: '3',
    imageUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/public/featured/beats-pro.jpg',
    title: 'Beats Studio Pro',
    celebrity: 'Dr. Dre',
    price: 349.99
  }
];

export const recommendedProducts = [
  {
    id: '4',
    imageUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/featured/fc7b3533-8fde-4d26-b832-8a7e97d5f451_RETINA_PORTRAIT_3_2.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9mZWF0dXJlZC9mYzdiMzUzMy04ZmRlLTRkMjYtYjgzMi04YTdlOTdkNWY0NTFfUkVUSU5BX1BPUlRSQUlUXzNfMi5qcGciLCJpYXQiOjE3MzM0NDI5NzUsImV4cCI6MTc2NDk3ODk3NX0.g6JCkHQBLuJX5L08uQSALTYcX99yhDkveUpPlEHk-DY',
    title: 'Taylor Swift | The Eras Tour 2024 ticket',
    celebrity: 'Taylor Swift',
    price: 599.99,
    purchaseUrl: 'https://www.ticketmaster.com/taylor-swift-tickets/artist/1094215'
  },
  {
    id: '5',
    imageUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/featured/8526834.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9mZWF0dXJlZC84NTI2ODM0LmpwZyIsImlhdCI6MTczMzQ0MzAzOCwiZXhwIjoxNzY0OTc5MDM4fQ.vZVQoRlcuCbGabmFuxB2RtIRHOcKgfPItCrPgH4DLy8',
    title: 'Fenty Beauty Set',
    celebrity: 'Rihanna',
    price: 199.99,
    purchaseUrl: 'https://fentybeauty.com/collections/value-sets-bundles'
  },
  {
    id: '6',
    imageUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/featured/gran-coramino-by-kevin-hart-anejo-tequila__04497.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9mZWF0dXJlZC9ncmFuLWNvcmFtaW5vLWJ5LWtldmluLWhhcnQtYW5lam8tdGVxdWlsYV9fMDQ0OTcuanBnIiwiaWF0IjoxNzMzMjY4ODkxLCJleHAiOjE3NjQ4MDQ4OTF9.455VTRODJHdqnxcT2WUW0yIZK3pgYdKprmhQRyJAhDM',
    title: 'Tequila Gran Coramino',
    celebrity: 'Kevin Hart',
    price: 149.99,
    purchaseUrl: 'https://grancoramino.com/tequilas/'
  }
];

export const trendingProducts = [
  {
    id: '7',
    imageUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/Stardom%20Auction%20videos/copy_9CE85F4A-5FAF-42A4-86AD-B83BF83B8783.mp4?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9TdGFyZG9tIEF1Y3Rpb24gdmlkZW9zL2NvcHlfOUNFODVGNEEtNUZBRi00MkE0LTg2QUQtQjgzQkY4M0I4NzgzLm1wNCIsImlhdCI6MTczMzc4MDQ3OCwiZXhwIjoxNzY1MzE2NDc4fQ.4KCayw30-dFB-u9b0LhCekydSf3ozhC9vSpra6MNswA',
    title: 'Live Auction',
    celebrity: 'Kim Kardashian',
    price: 249.99,
    isLive: true
  },
  {
    id: '8',
    imageUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/featured/kylie%20holiday.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9mZWF0dXJlZC9reWxpZSBob2xpZGF5LndlYnAiLCJpYXQiOjE3MzM3ODg4MzUsImV4cCI6MTc2NTMyNDgzNX0.Td7htenCb9FJi9mzWgoo4-Zivwg4XmPB9syh6RFB4aU',
    title: 'Kylie Cosmetics Bundle',
    celebrity: 'Kylie Jenner',
    price: 249.99,
    purchaseUrl: 'https://kyliecosmetics.com/collections/kylie-cosmetics-bundles-sets'
  },
  {
    id: '9',
    imageUrl: 'https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/featured/ce3SwsNJRtiLU96MqFEfa3WU.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9mZWF0dXJlZC9jZTNTd3NOSlJ0aUxVOTZNcUZFZmEzV1Uud2VicCIsImlhdCI6MTczMzQ0MjM1MCwiZXhwIjoxNzY0OTc4MzUwfQ.G_F8du8KkeiwdfzSmw-3AIA1l9ac_5pkd630e93jp_g',
    title: 'Cooking Masterclass',
    celebrity: 'Gordon Ramsay',
    price: 79.99,
    purchaseUrl: 'https://www.masterclass.com/classes/gordon-ramsay-teaches-cooking'
  }
];