import React, { useState, useCallback } from 'react';
import { ImageIcon, Upload, AlertCircle } from 'lucide-react';
import { isValidImageFile } from '../../utils/validators/imageValidators';

interface ImageUploadProps {
  currentImage: string;
  onImageChange: (imageUrl: string) => void;
  className?: string;
}

export function ImageUpload({ currentImage, onImageChange, className = '' }: ImageUploadProps) {
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleDrag = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragging(true);
    } else if (e.type === 'dragleave') {
      setIsDragging(false);
    }
  }, []);

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    setError(null);

    const file = e.dataTransfer.files[0];
    if (file) {
      if (!isValidImageFile(file)) {
        setError('Please upload a valid image file (JPEG, PNG, GIF, or WebP)');
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          onImageChange(event.target.result as string);
        }
      };
      reader.onerror = () => {
        setError('Failed to read image file');
      };
      reader.readAsDataURL(file);
    }
  }, [onImageChange]);

  return (
    <div
      className={`relative group cursor-pointer ${className}`}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      {currentImage ? (
        <img
          src={currentImage}
          alt="Product"
          className={`w-full h-full object-cover transition-opacity duration-200 ${
            isDragging ? 'opacity-50' : 'group-hover:opacity-75'
          }`}
          onError={() => setError('Failed to load image')}
        />
      ) : (
        <div className="w-full h-full bg-gray-100 flex items-center justify-center">
          <ImageIcon className="w-8 h-8 text-gray-400" />
        </div>
      )}
      
      <div className={`absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 transition-opacity duration-200 ${
        isDragging ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'
      }`}>
        <Upload className="w-8 h-8 text-white mb-2" />
        <p className="text-white text-sm text-center px-4">
          {isDragging ? 'Drop image here' : 'Drag & drop new image'}
        </p>
      </div>

      {error && (
        <div className="absolute bottom-0 left-0 right-0 bg-red-500 text-white text-sm py-2 px-4 flex items-center justify-center gap-2">
          <AlertCircle className="w-4 h-4" />
          <span>{error}</span>
        </div>
      )}
    </div>
  );
}