import { createClient, SupabaseClient } from '@supabase/supabase-js';

export class SupabaseService {
  private static instance: SupabaseService;
  private supabaseClient: SupabaseClient;
  private refreshPromise: Promise<void> | null = null;

  private constructor() {
    const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
    const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;
    
    if (!supabaseUrl || !supabaseKey) {
      throw new Error('Missing Supabase configuration');
    }

    this.supabaseClient = createClient(supabaseUrl, supabaseKey, {
      auth: {
        persistSession: true,
        autoRefreshToken: true,
        detectSessionInUrl: true,
        storageKey: 'stardom-auth-token',
        storage: localStorage
      }
    });

    // Set up session refresh handling
    this.supabaseClient.auth.onAuthStateChange((event, session) => {
      if (event === 'TOKEN_REFRESHED' && !this.refreshPromise) {
        this.refreshPromise = this.handleTokenRefresh();
      }
    });
  }

  private async handleTokenRefresh(): Promise<void> {
    try {
      await this.supabaseClient.auth.getSession();
    } catch (error) {
      console.error('Token refresh error:', error);
      await this.signOut();
    } finally {
      this.refreshPromise = null;
    }
  }

  static getInstance(): SupabaseService {
    if (!SupabaseService.instance) {
      SupabaseService.instance = new SupabaseService();
    }
    return SupabaseService.instance;
  }

  get storage() {
    return this.supabaseClient.storage;
  }

  get auth() {
    return this.supabaseClient.auth;
  }

  async signInWithPassword(credentials: { email: string; password: string }) {
    return this.auth.signInWithPassword(credentials);
  }

  async signOut() {
    await this.auth.signOut();
    localStorage.removeItem('stardom-auth-token');
  }

  async getSession() {
    return this.auth.getSession();
  }
}