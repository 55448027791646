interface RateLimiterConfig {
  maxRequests: number;
  timeWindow: number; // in milliseconds
}

class RateLimiter {
  private requests: number[] = [];
  private config: RateLimiterConfig;

  constructor(config: RateLimiterConfig) {
    this.config = config;
  }

  canMakeRequest(): boolean {
    const now = Date.now();
    this.requests = this.requests.filter(
      timestamp => now - timestamp < this.config.timeWindow
    );
    return this.requests.length < this.config.maxRequests;
  }

  addRequest(): void {
    this.requests.push(Date.now());
  }
}

export const searchRateLimiter = new RateLimiter({
  maxRequests: 10,
  timeWindow: 60000 // 1 minute
});