import React from 'react';
import { TrendingUp } from 'lucide-react';
import { ProductCard } from '../product/ProductCard';
import { trendingProducts } from '../../data/products';

export function TrendingSection() {
  return (
    <section className="py-8">
      <div className="flex items-center gap-2 mb-6">
        <TrendingUp className="w-6 h-6 text-stardom-light" />
        <h2 className="text-2xl font-bold dark:text-white">Trending Now</h2>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {trendingProducts.map((product) => (
          <ProductCard key={product.id} {...product} />
        ))}
      </div>
    </section>
  );
}