import OpenAI from 'openai';
import { searchRateLimiter } from '../utils/rateLimiter';
import { ERROR_MESSAGES } from '../utils/constants';

export class StardomAIService {
  private static instance: StardomAIService;
  private openai: OpenAI;

  private constructor() {
    this.openai = new OpenAI({
      apiKey: import.meta.env.VITE_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true
    });
  }

  static getInstance(): StardomAIService {
    if (!StardomAIService.instance) {
      StardomAIService.instance = new StardomAIService();
    }
    return StardomAIService.instance;
  }

  async getCelebrityInfo(name: string): Promise<string> {
    try {
      if (!searchRateLimiter.canMakeRequest()) {
        throw new Error(ERROR_MESSAGES.RATE_LIMIT);
      }

      searchRateLimiter.addRequest();

      const completion = await this.openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content: `You are StardomAI, a knowledgeable assistant that provides concise information about celebrities from their Wikipedia pages. Focus on their career highlights, achievements, and current projects. Keep responses brief and engaging.`
          },
          {
            role: "user",
            content: `Who is ${name}?`
          }
        ],
        model: "gpt-3.5-turbo",
        temperature: 0.7,
        max_tokens: 150,
      });

      return completion.choices[0].message.content || 'Information not available.';
    } catch (error) {
      console.error('StardomAI Error:', error);
      return 'Sorry, I cannot provide information at the moment. Please try again later.';
    }
  }
}