import React from 'react';
import { BackButton } from '../../components/common/BackButton';

export function NewsPage() {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-6">
      <BackButton />
      <div className="max-w-7xl mx-auto mt-8">
        <h1 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white">Celebrity News</h1>
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden">
          <img
            src="https://ddlvbtbyvlqpiizexmlr.supabase.co/storage/v1/object/sign/product-images/Stardom%20News/stardom%20news.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0LWltYWdlcy9TdGFyZG9tIE5ld3Mvc3RhcmRvbSBuZXdzLnBuZyIsImlhdCI6MTczMzc3NzU5NywiZXhwIjoxNzY1MzEzNTk3fQ.rUopfu6W-0JuNBq_2369-5d_aNkciAXVy0FIuMCGg2Y"
            alt="Celebrity News"
            className="w-full h-auto"
          />
          <div className="p-6">
            <h2 className="text-xl font-bold mb-2 text-gray-900 dark:text-white">
              Breaking Celebrity News
            </h2>
            <p className="text-gray-600 dark:text-gray-400">
              Stay updated with the latest celebrity news, trends, and exclusive content.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}