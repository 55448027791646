import React from 'react';
import { CategoryCard } from '../../components/category/CategoryCard';

const categories = [
  {
    title: 'Clothing and Apparel',
    imageUrl: 'https://images.unsplash.com/photo-1523381210434-271e8be1f52b',
    slug: 'clothing-apparel'
  },
  {
    title: 'Accessories',
    imageUrl: 'https://images.unsplash.com/photo-1523381140794-a1eef18a37c7',
    slug: 'accessories'
  },
  {
    title: 'Footwear',
    imageUrl: 'https://images.unsplash.com/photo-1542291026-7eec264c27ff',
    slug: 'footwear'
  },
  {
    title: 'Beauty and Personal Care',
    imageUrl: 'https://images.unsplash.com/photo-1522338242992-e1a54906a8da',
    slug: 'beauty-personal-care'
  },
  {
    title: 'Music and Audio',
    imageUrl: 'https://images.unsplash.com/photo-1511379938547-c1f69419868d',
    slug: 'music-audio'
  },
  {
    title: 'Books and Publications',
    imageUrl: 'https://images.unsplash.com/photo-1524995997946-a1c2e315a42f',
    slug: 'books-publications'
  },
  {
    title: 'Entertainment Media',
    imageUrl: 'https://images.unsplash.com/photo-1522869635100-9f4c5e86aa37',
    slug: 'entertainment-media'
  },
  {
    title: 'Collectibles',
    imageUrl: 'https://images.unsplash.com/photo-1529448005898-b19fc13465b7',
    slug: 'collectibles'
  },
  {
    title: 'Event Tickets',
    imageUrl: 'https://images.unsplash.com/photo-1514525253161-7a46d19cd819',
    slug: 'event-tickets'
  },
  {
    title: 'Home and Living',
    imageUrl: 'https://images.unsplash.com/photo-1513694203232-719a280e022f',
    slug: 'home-living'
  },
  {
    title: 'Food and Beverage',
    imageUrl: 'https://images.unsplash.com/photo-1514933651103-005eec06c04b',
    slug: 'food-beverage'
  },
  {
    title: 'Tech and Electronics',
    imageUrl: 'https://images.unsplash.com/photo-1519389950473-47ba0277781c',
    slug: 'tech-electronics'
  }
];

export function CategoryBrowse() {
  return (
    <div className="px-6 py-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold mb-2 text-gray-900 dark:text-white">
          Browse by Category
        </h1>
        <p className="text-gray-600 dark:text-gray-400 mb-8">
          Explore exclusive celebrity products across different categories
        </p>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {categories.map((category) => (
            <CategoryCard
              key={category.slug}
              title={category.title}
              imageUrl={category.imageUrl}
              slug={category.slug}
            />
          ))}
        </div>
      </div>
    </div>
  );
}