import React from 'react';
import { Bell } from 'lucide-react';
import { ThemeToggle } from '../common/ThemeToggle';
import { UserMenu } from '../common/UserMenu';
import { CartDropdown } from '../cart/CartDropdown';
import { Logo } from '../common/Logo';

export function Header() {
  return (
    <header className="bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 px-4 sm:px-8 py-3 transition-colors">
      <div className="flex items-center justify-between">
        <div className="lg:hidden">
          <Logo size="small" />
        </div>
        <div className="flex items-center gap-4">
          <ThemeToggle />
          <button className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors">
            <Bell className="w-5 h-5 text-gray-700 dark:text-gray-300" />
          </button>
          <CartDropdown />
          <UserMenu />
        </div>
      </div>
    </header>
  );
}