import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';
import { AuthProvider } from './contexts/AuthContext';
import { CartProvider } from './contexts/CartContext';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import { NewsTicker } from './components/common/NewsTicker/NewsTicker';
import { Login } from './pages/auth/Login';
import { SignUp } from './pages/auth/SignUp';
import { Dashboard } from './pages/Dashboard';

const DISCLAIMER_MESSAGE = "The images and videos featured in this demo are used solely for illustrative purposes to showcase StardomApp's concept. The celebrities and merchandise displayed are not affiliated with StardomApp, and the content does not imply endorsement or partnership.";

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <AuthProvider>
          <CartProvider>
            <div className="relative min-h-screen pb-8">
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route
                  path="/dashboard/*"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route path="/" element={<Navigate to="/dashboard" replace />} />
              </Routes>
            </div>
            <NewsTicker 
              message={DISCLAIMER_MESSAGE}
              speed={40}
              backgroundColor="rgba(0, 0, 0, 0.9)"
              textColor="rgba(255, 255, 255, 0.9)"
            />
          </CartProvider>
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;