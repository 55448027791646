import React from 'react';
import { Link } from 'react-router-dom';

interface CategoryCardProps {
  title: string;
  imageUrl: string;
  slug: string;
}

export function CategoryCard({ title, imageUrl, slug }: CategoryCardProps) {
  return (
    <Link 
      to={`/dashboard/category/${slug}`}
      className="relative overflow-hidden rounded-xl group"
    >
      <div className="aspect-square">
        <img 
          src={imageUrl} 
          alt={title}
          className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
        />
        <div className="absolute inset-0 bg-black bg-opacity-40 transition-opacity group-hover:bg-opacity-50">
          <div className="absolute bottom-0 left-0 right-0 p-4">
            <h3 className="text-white text-xl font-semibold">{title}</h3>
          </div>
        </div>
      </div>
    </Link>
  );
}